import { LanguageProvider } from '@zert-packages/utils/LanguageProvider';
import { PortalProvider } from '@zert-packages/utils/PortalProvider';
import React from 'react';
import './index.css';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { SnackbarProvider, useSnackbar } from 'notistack';
import 'intersection-observer';
import { commonReducer, initialState } from '@zert-packages/actions';

import {
  pushStore,
  getStore,
  pushPlugins,
  setTilesFunc,
  setPublishFunc,
  setPreviewFunc
} from '@zert-packages/actions/api';
import { EXPLORERReducers } from '@zert-packages/plugins/ExplorerPlugin/explorerReducers';
import { AuthProvider } from '@zert-packages/plugins/auth/JWTAuthContext';
import getPlugins, { actionPlanPlugin, setProductActions } from '@zert-packages/plugins/Environment';
import { App } from '@zert-packages/components/Content/App';
import withClearCache from '@zert-packages/components/Content/ClearCache';
import { EnvProvider } from '@zert-packages/react/EnvProvider';
import { createRoot } from 'react-dom/client';
import { CLMReducer } from './plugins/TranslationJobPlugin/tjReducers';
import { PreviewReducer } from './plugins/PreviewPlugin/previewReducers';
import getCLMPlugins, { getTileElementByMimeType } from './plugins/Environment';
import { PublishingReducer } from './plugins/PublishingPlugin/publishingReducers';
import { createPublishing } from './plugins/PublishingPlugin/servicesMiddlewares';
import { generatePreviewReport } from './plugins/PreviewPlugin/servicesMiddlewares';
import { exportHTML } from './plugins/PublishingPlugin/AdditionalActions';
import packageJson from '../package.json';
import registerServiceWorker from './registerServiceWorker';
import { hooksInMiddleware } from "@zert-packages/utils/SnackBarsNotistack";


require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/sv');
require('@formatjs/intl-pluralrules/locale-data/en');

const context = require.context('./translations/', true, /\/([a-z]{2})\.json$/);


export function CreateHooksInMiddleware() {
  hooksInMiddleware.snackbar = useSnackbar();
  return null;
}

const loggerMiddleware = createLogger();

function configureStore(preloadedState) {
  return createStore(
    (state, action) => {
      return {
        ...commonReducer(state, action),
        //  RMP: RMPReducer(state.RMP, action),

        CLM: CLMReducer(state.CLM, action),
        CORE: EXPLORERReducers(state.CORE, action),
        PREVIEW: PreviewReducer(state.PREVIEW, action),
        PUBLISHING: PublishingReducer(state.PUBLISHING, action)
        //  QUESTIONNAIRE: QUESTIONNAIREReducer(state.QUESTIONNAIRE, action),
        // DEVIATION: DeviationReducers(state.DEVIATION, action)
      };
    },
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware.withExtraArgument(hooksInMiddleware)
        // loggerMiddleware,
      )
    )
  );
}

export const TASK_ACTIONS = [exportHTML];

setProductActions(TASK_ACTIONS);
pushPlugins([...getPlugins(), ...getCLMPlugins(), actionPlanPlugin]);
pushStore(configureStore(initialState));
const store = getStore();
setTilesFunc(getTileElementByMimeType);
setPublishFunc(createPublishing);
setPreviewFunc(generatePreviewReport);

// const history = createHistory();
// const historyMiddleware = routerMiddleware(loggerMiddleware);
// const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, historyMiddleware)(createStore);
// const store = createStoreWithMiddleware(rootReducer);

/* const store = configureStore({
  reducer: commonReducer,
}); */
const element = document.getElementById('ipl-progress-indicator');
element.parentNode.removeChild(element);







function ClearCacheComponent({ children }) {
  return children;
}

const ClearCache = withClearCache(ClearCacheComponent, packageJson);

const container = document.getElementById('react');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <EnvProvider>
        <ClearCache />
        <PortalProvider>
          <LanguageProvider translationContext={context}>
            <SnackbarProvider maxSnack={5}>
              <AuthProvider>
                <CreateHooksInMiddleware />
                <App />
              </AuthProvider>
            </SnackbarProvider>
          </LanguageProvider>
        </PortalProvider>
      </EnvProvider>
    </Provider>
  </BrowserRouter>
);

registerServiceWorker();

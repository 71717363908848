import React, { Fragment, useEffect } from 'react';
import { Settings } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

import { exportHTMLALO } from './servicesMiddlewares';

export const exportHTML = {
  icon: <Settings />,
  id: 'exportHTML',
  name: <FormattedMessage id="ElementTile.Actions.ExportHTML" defaultMessage="Export Html" />,
  executeAction: (selectElement, dispatch) => {
    dispatch(exportHTMLALO(selectElement));
  }
};
